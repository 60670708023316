@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap');
body {
    margin: 0;
    font-family: "Poppins-ExtraLight, Poppins-Regular , Poppins-Medium , Poppins Medium, Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
    box-sizing: border-box;
  }
  
  
  #root{
    background: white;
  }

  /* COMMON */
  .crio-word-count {
    position: absolute;
    top: 130px;
    right: 12%;
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
    color: #cccccc !important;
    font-size: 14px;
  }
  .ant-table-content {
    min-height: 350px !important;
  }
  .ant-divider-dashed{
    border-color: rgba(5, 5, 5, 0.2) !important;
  }
  .ant-modal-confirm-btns {
    text-align: left !important;
    margin-left: 67px;
  }
  .ant-modal-confirm-content {
    margin-left: 67px;
  }
  .ant-modal-confirm-title{
    flex: unset !important;
    margin-left: 17px !important;
  }
  .ant-tooltip-inner {
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  }
  .react-datepicker {
    border : none !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 15px;
  }
  .date-picker-select:focus {
    outline: none !important;
  }
  .pi-date-picker-error {
    border-bottom: 3px solid #c04040 !important;
  }
  .react-datepicker__input-container input {
    width: 100%;
    max-width: 244px;
    height: 26px;
    font-size: 14px !important;
    font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
    font-weight: 300 !important;
    border: 1px solid #e6eaf2 ;
    background-color: #fafbfc !important;
    border-radius: 0% !important;
    margin-bottom: 10px !important;
  }
  .react-datepicker__input-container input:focus {
    border: 1px solid #e6eaf2 !important;
    outline: none !important;
  }
  .react-datepicker__header {
    background-color: #fff !important;
    border: none !important;
  }
  .react-datepicker__day--selected  {
    background-color: #e3761c !important;
    color: #fff !important;
  }
  .react-datepicker__day-name {
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
    color: #999 !important;
    font-size: 14px !important;
    width: 40px !important;
  }
  .react-datepicker__day{
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
    color: #666666;
    font-size: 14px !important;
    width: 40px !important;
  }
  .react-datepicker__day:hover {
    color: #e3761c ;
    background-color: #fff ;
  }
  .react-datepicker__day--keyboard-selected:hover {
    color: #fff !important;
    background-color: #fff ;
  }
  .datepicker-left-button, .datepicker-right-button {
    cursor: pointer;
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
    color: #666666;
    font-size: 20px;
  }
  .datepicker-left-button {
    margin-right: 20px;
  }
  .datepicker-right-button {
    margin-left: 20px;
  }
  .body-container {
    margin: 0 8%;
  }
  .ant-row {
    row-gap: 0 !important;
  }
  .ant-form-item {
    margin: 10px 0;
  }
  .crio-input {
    height: 40px;
    font-size: 14px !important;
    font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
    font-weight: 300 !important;
    border: 1px solid #e6eaf2 !important;
    background-color: #fafbfc !important;
    border-radius: 0% !important;
    width: 90%;
  }
  .crio-input input, .logo-modal-input-group input {
    background-color: #fafbfc !important;
    font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  }
  .vertical-divider-class {
    top: 6% !important;
    height: 88% !important;
    border-block-start: 1px dashed #c9c9c9 !important;
  }
  .vertical-divider-class-2 {
    width: 1px;
    height: 100% ;
    border-left: 1px dashed #ccc ;
    margin-top: 40px;
  }
  .horizontal-divider-class {
    margin: 0 !important;
    left: 6% !important;
    width: 88% !important;
    border-block-start: 1px dashed #c9c9c9 !important;
  }
  .ant-form-item-explain-error{
    color: #c04040 !important;
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 19px !important;
  }

  .ant-input-status-error, .ant-select-status-error {

    border-bottom: 3px solid #c04040 !important;
    margin-bottom: 10px !important;
  }

  .pop-reg-666 {
    font-family: "Poppins-Regular", "Poppins", sans-serif;
    color: #666666;
    font-size: 14px;
  }
  .ant-checkbox-inner{
    background-color: #fff !important;
    border-radius: 0 !important;
    border-color: #cccccc !important;
  }
  .ant-checkbox-inner::after {
    border-color: #19b5ff !important;
  }

  .spinner-loader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Poppins-Regular", "Poppins", sans-serif;
    color: #666666;
    font-size: 18px;
    margin: 20px 0;
  }
  .spinner-wrapper {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /*          HEADER  START           */
  .header {
    height: 80px;
    color: white;
    background-color: rgb(13, 43, 58);
    font-size: 18px;
    display: flex;
    padding: 0 8%;
    justify-content: space-between;
    align-items: center;
  }

  .header ul {
    display: flex;
    align-items: center;
    margin:0% !important ;
  }

  .header ul li {
    list-style: none;
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
    margin-left: 20px;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 100%;
    padding: 0 10px;
    position: relative;
    width: 160px;
  }
  .logo img {
    height: 60px;
    max-width: 160px;
    cursor: pointer;
  }
  .logo .ant-skeleton-image {
    max-height: 60px;
  }
  .edit-logo-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: brightness(0.5);
  }
  .devider-logo-div{
    height: 2em;
    border: 1px solid #fff;
    margin: 0 15px;
  }
  .edit-logo {
    border: 4px solid #fff;
    border-radius: 50%;
    padding: .7rem;
    font-size: 1rem;
    cursor: pointer;
  }
  .logo-update-title {
    font-family: "Poppins-Regular", "Poppins", sans-serif;
    color: #0d2b3a;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    line-height: normal;
  }
  .logo-update-description {
    font-family: "Poppins-ExtraLight", "Poppins ExtraLight", "Poppins", sans-serif;
    font-weight: 300;
    color: #666666;
    text-align: left;
    line-height: 22px;
    font-size: 14px;
  }
  .logo-update-right h6{
    font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
    font-weight: 500;
    color: #666666;
    font-size: 14px;
    margin:0 0 10px 0;
  }
  .logo-update-right ul li {
    font-family: "Poppins-Regular", "Poppins", sans-serif;
    color: #666666;
    font-size: 14px;
    font-weight: 300;
  }
  .logo-update-right ul li span{
    font-weight: 400;
  }
  .logo-modal-cancel, .logo-modal-ok, .logo-modal-upload {
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
    font-size: 14px !important;
    border-radius: 3px !important;
    padding: 8px 20px !important;
    height: 40px !important;
    box-shadow: unset !important;
  }
  .logo-modal-ok {
    background-color: #4e6d8b !important;
    color: #fff !important;
  }
  .logo-modal-input-group{
    display: flex !important;
    margin: 10px 0 30px 0 !important;
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  }
  .logo-modal-input {
    width: calc(100% - 100px) !important;
    height: 40px !important;
    background-color: rgba(250, 251, 252, 1) !important;
    border-radius: 3px 0 0 3px !important;
    outline: none !important;
  }
  .logo-modal-input:hover, .logo-modal-input:focus-within {
    outline: none !important;
    border-color: #bfbfbf !important;
    backdrop-filter: unset !important;
    box-shadow: unset !important;
  }
  .logo-modal-upload {
    border-radius: 0 3px 3px 0 !important;
  }
  .logo-modal-ok:hover {
    background-color: rgb(55,77,98) !important;
  }
  .logo-modal-cancel, .logo-modal-upload {
    background-color: #ccc !important;
    color: #fff !important;
    border-color: #bfbfbf !important;
  }
  .logo-modal-cancel:hover, .logo-modal-upload:hover {
    background-color: #bfbfbf !important;
  }
  .logo-update-img-wrapper {
    width: 200px;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-update-img{
    max-width: 100%;
    max-height: 100%;
  }
  .ant-modal-footer {
    display: flex !important;
  }
  .profile-nav-img {
    height: 40px;
    width: 40px;
    background: #cacaca;
    border-radius: 50%;
    overflow: hidden;
    border : 1px solid #eeeeee;
  }
  .profile-nav-img img {
    height: 100%;
    width: 100%;
  }
  .org-nav-name {
    font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    color: #e3761c;
    width: -webkit-fill-available;
    margin: 0 10%;
  }
  .org-nav-name-white {
    font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    width: -webkit-fill-available;
    margin: 0 10%;
  }

  .modalUploadButton {
    font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
    height: 24px;
    margin: 0;
    padding: 0 5px;
    border-radius: 3px !important;
    background-color: #efefef;
    border: 1px solid black !important;
  }
  .modalUploadButton:hover{
    color: #000 !important;
    background-color: #dfdfdf !important;
  }
  .header-nav {
    color: white !important;
    font-size: 16px !important;
    background: none !important;
    width: -webkit-fill-available;
  }
  .ant-menu-item:hover {
    color:#e3761c !important;
  }
  .ant-menu-item-selected {
    color:#e3761c !important;
    background: none !important;
  }
  /*         HEADER  END       */

  /*         FOOTER  START       */

  .footer {
    height: 170px;
    width: 100%;
    background-color: rgb(13, 43, 58);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #2d92b2;
    text-align: center;
  }
  .footer-branding {
    margin-bottom: 16px;
    font-size: 14px;
    font-family: "Poppins-Regular", "Poppins", sans-serif;
    display: flex;
    align-items: end;
  }
  .footer-branding img{
    height: 34px;
    margin-right: 10px;
  }
  .footer-rights {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Poppins-ExtraLight", "Poppins ExtraLight", "Poppins", sans-serif;
    width: 90%;
    font-weight: 100 !important;
    font-size: 14px;
    max-width: 900px;
    min-height: 80px;
  }
  .footer-rights a {
    text-decoration: none !important;
  }
  .footer-rights p {
    margin: 0 !important;
  }
  .footer-rights a:nth-child(1), .footer-rights a:nth-child(2) {
    color: rgba(255, 255, 255, 0.5) !important;
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
    font-weight: 400 !important;
  }

/*         FOOTER  END       */
/*         HOME  START       */
.home-loading{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-body {
  overflow: hidden;
}
.home-body-container {
  margin: 0 8%;
}
.home-body-container-img{
  position: relative;
}
.home-body-container-img:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('/src/assets/images/termsandagreement-rocket.svg');
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: -20% 310px;
  z-index: 2;
}
.home-body-container-img:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-image: url('/src/assets/images/backdrop.png');
  background-repeat: no-repeat;
  background-position:6% 420px;
  background-size: 60%;
  z-index: 1;
}
.tc-left {
  height: 100%;
}
.tc-right {
  z-index: 3;
}
.hero-section {
  position: relative;
  left : 15%;
  margin-top: 150px;
  max-width: 350px;
}
.hero-section h3 {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 28px;
}
.hero-section p {
  font-family: "Poppins-Light", "Poppins Light", "Poppins", sans-serif;
  font-weight: 300;
  color: #666666;
  font-size: 14px;
}
.agreement-card-wrapper {
  margin: 50px 0 70px 50px;
  border-radius: 10px;
  box-shadow: -15px -15px 25px 0px rgba(204, 204, 204, 0.35);
}
.agreement-card {
  /* margin: 50px 0 70px 50px; */
  padding: 26px 26px;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  /* box-shadow: -15px -15px 25px 0px rgba(204, 204, 204, 0.35); */
}
.agreement-header {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 16px;
}
.agreement-body {
  margin: 23px 0;
  padding: 40px;
  border: 1px solid #eeeeee;
  font-family: "Poppins-Light", "Poppins Light", "Poppins", sans-serif;
  font-weight: 200;
  color: #666666;
  font-size: 14px;
}
.agreement-body-text {
  height: 330px;
  padding-right: 10px;
  overflow-y: auto;
  font-family: "Poppins-Light", "Poppins Light", "Poppins", sans-serif;
  font-weight: 300;
  color: #666666;
  font-size: 14px;
}

.agreement-footer-text{
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #666666;
  font-size: 12px;
  width: 100%;
}
.agreement-footer-button {
  min-width: 140px;
  margin: 20px 0;
  border-radius: 3px !important;
  height: 40px;
  background-color: #4e6d8b;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
}
.agreement-footer-button:hover {
  background-color: rgb(66,91,117) !important;
}
.agreement-footer-button span {
  margin-left: 8px;
}
.agreement-img{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.agreement-img img{
  max-height: 190px;
}
.premium-body-text {
  position: relative;
  background: linear-gradient(0deg, rgba(255,255,255,1) 93%, rgba(255,255,255,0) 100%);
  padding-top: 70px;
  margin-top: -80px;
  z-index: 99;
  
}
.premium-body-text .ant-col, .premium-body-text {
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #666666 !important;
  text-align: left;
  font-weight: 400;
  line-height: 22px !important;
  font-size: 14px !important;
}
.premium-body-text span {
  font-weight: 500;
}

/*      ORG PROFILE SETUP Starts      */

.body-container-setup{
  margin: 0 8%;
  display:flex;
  justify-content: center;
}
.body-container-setup-header {
  width: 881px;
  margin-top: 90px;
}

.step-wrapper{
  display: flex;
  width: 100%;
  border-bottom: 2px solid #cccccc;
}

.step-1{
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.step-2{
  cursor: pointer;
}

.step-1-icon{
  margin-bottom: 20px;
  font-size: 18px;
  color: #13b639;
}

.step-dots{
  display: flex;
  align-items: center;
  margin: 26px;
}

.step-dots div{
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: #cccccc;
  margin: 0 8px;
}

.step-green-dots{
  display: flex;
  align-items: center;
  margin: 26px;
}

.step-green-dots div{
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: #13b639;
  margin: 0 8px;
}

.step-active{
  border-bottom: 2px solid #e57e2b;
}

.step-active h3{
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  
}

.step-active p {
  color: #e57e2b;
  height: 5px;
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  text-align: left;
}
.step-inactive h3{
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #cccccc;
  text-align: center;
}

.step-inactive p{
  color: #cccccc;
  height: 5px;
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  text-align: left;
}

.step-progress p {
  color: #13b639;
  height: 5px;
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  text-align: left;
}

.step-progress h3 {
  color: #13b639;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  
  text-align: center;
  
}

.step-progress h3:hover{
  color: #cccccc;
  
}


.step-2-active{
  border-bottom: 2px solid #e57e2b;
}

.step-2-active h3{
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  
}

.step-2-active p {
  color: #e57e2b;
  height: 5px;
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  text-align: left;
}

.step-2-active h3:hover{
  color: #cccccc;
  
}

.step-2-active p:hover{
  color: #36ade9;
  
}

.content-area{

  display: block;
}

.sticky-footer{
  background-color: #13b639;
  width: 1390px;
  height: 94px;
  padding: 2px 2px 2px 2px;
}
.body-container-setup-footer {
    height: 100px;
    width: 100%;
    position: sticky;
    box-shadow: 0px -3px 10px 0px rgb(0 0 0 / 20%);
    bottom: 0;
    background-color: white;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}
.body-container-setup-footer-inner {
    width: 881px;
    display: flex;
    justify-content: flex-end;
    margin: 0 8%;
}

/*       ORG PROFILE Starts       */
.op{
  margin: 30px 0;
}
.op-left-title {
  margin: 20px 0;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 28px;
}
.op-p-i{
  font-family: "Poppins-Italic", "Poppins Italic", "Poppins", sans-serif !important;
  font-style: italic !important;
  color: #999999 !important;
  line-height: 22px !important;
  font-size: 14px !important;
}
.op-p, .op-p-12 {
  font-family: "Poppins-ExtraLight", "Poppins ExtraLight", "Poppins", sans-serif;
  font-weight: 300;
}
.op-p {
  color: #666666;
  font-size: 14px !important;
}
.op-p-12 {
  font-size: 12px !important;
  width: 90%;
  font-family: "Poppins-Light", "Poppins Light", "Poppins", sans-serif !important;
  font-weight: 200 !important;
  color: #999999 !important;
}

.org-premium-title{
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 16px;
  margin-right: -30px;
  margin-bottom: 22px;
}
.org-premium-title span{
  cursor: pointer;
  margin-left: 5px;
}
.agreement-footer-button-org {
  margin-left: 24px;
}
.spin-loader-div-container{
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.agreement-body-text-modal {
  max-height: 65vh;
  padding-right: 10px;
  overflow-y: scroll;
  font-family: "Poppins-Light", "Poppins Light", "Poppins", sans-serif;
  font-weight: 300;
  color: #666666;
  font-size: 14px;
}
.premium-p {
  font-family: "Poppins-ExtraLight", "Poppins ExtraLight", "Poppins", sans-serif;
  font-weight: 300;
  color: #666666;
  text-align: left;
  line-height: 20px;
  font-size: 12px;
}
.op-right-header {
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #0d2b3a;
  font-size: 18px !important;
  margin-bottom: 20px;
  margin-top: 40px;
}
.op-warning-card-wrapper {
  margin: 35px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.op-warning-card {
  max-width: 330px;
  width: 60%;
  padding: 20px 15px;
  border: 1px dashed #cccccc;
}
.certification-tooltip p{
  color: #0d2b3a !important;
  margin: 0 0 10px 0 !important;
  font-weight: 500 !important;
}
.certification-tooltip-icon{
  cursor: pointer;
  margin-left: 8px;
}
.op-label {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #666666;
  font-size: 14px;
  margin: 10px 0;
}
.op-label .ant-select .ant-select-in-form-item {
  height: auto !important;
}
.op-label .crio-input {
  height: auto;
}
.op-label p{
  font-family: "Poppins-Light", "Poppins Light", "Poppins", sans-serif;
  font-weight: 200;
  color: #999999;
  font-size: 12px;
}
.op-logo{
  margin: 20px 0;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  max-width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px;
}
.op-logo .ant-skeleton-image {
  max-height: 100% !important;
  max-width: 100% !important;
}
.op-logo img{
  max-width: 100%;
  max-height: 100% !important;
}
.op-checkbox {
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #666666;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.op-form-button-wrapper {
  display: flex;
  justify-content: end;
  width: 90%;
}
.op-form-button-wrapper Button {
  border-radius: 3px !important;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}


/*       ORG PROFILE Ends       */

/*       ADMIN PROFILE STARTS         */
.user-profile {
  height: 75vh;
  margin: 40px 10%;
}
.user-profile-left{
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #0d2b3a !important;
  font-size: 32px;
}
.user-profile-left-img{
  height: 128px;
  width: 128px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border:4px solid #bfbfbf ;
  border-radius: 50%;
  margin-top: 33px;
  background-color: #cacaca;
  color: #fff;
}
.user-profile-left-img img {
  height: 100%;
  width: 100%;
}
.user-profile-right{
  margin: 30px 10px;
}
.user-profile-right-text{
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  color: #666666 !important;
  font-size: 16px;
  line-height: 40px;
}

/*       ADMIN PROFILE  ENDS        */

/*  Site Header Content Starts    */

.site-edit-page-load-more {
  margin-bottom: 50px;
}
.site-edit-page-load-more span p {
  border-radius: 5px !important;
  border: 1px solid #dddddd !important;
}
.site-setup {
  max-width: 881px;
  margin-top: 35px;
  width: 881px;
}
.search-site-setup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
}
.search-site-setup .dashboard-search{
  margin-bottom: 0px!important;
}

.site-setup-header div {
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #0d2b3a;
  font-size: 18px !important;
}
.site-header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.site-header-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
}
.site-expand-icon {
  font-size: 20px !important;
  color: #666666 !important;
}
.site-header-name {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #0d2b3a !important;
  font-size: 18px !important;
  cursor: pointer;
}
.site-edit-p{
  margin-top: 30px;
}
.site-edit-p .ant-switch {
  width: 90px !important;
  height: 40px !important;
}
.site-edit-p .ant-switch-handle::before {
  height: 40px !important;
  width: 46px !important;
}
.site-edit-title {
  margin-bottom: 15px;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 28px;
}
.site-progress-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  cursor: pointer;
}
.site-progress-bar-for-single {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.site-progress-bar .bar, .site-progress-bar-for-single .bar {
  border-radius: 5px;
  border: 1px solid #cccccc;
  height: 10px;
  width:50%;
  margin-right: 10px;
  overflow: hidden;
}
.site-progress-bar-for-single .bar {
  width:55% !important;
}
.site-progress-bar .bar .bar-fill, .site-progress-bar-for-single .bar .bar-fill{
  height: 10px;
  border-radius: 5px;
}
.bar-text {
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #666666;
}
.site-setup-body-text {
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #cccccc;
  font-size: 11px;
  margin: 50px 0;
  max-width: 350px;
}
.site-setup-body-text-i {
  font-family: "Poppins-Italic", "Poppins Italic", "Poppins", sans-serif !important;
  font-style: italic !important;
  color: #999999 !important;
  font-size: 11px;
  margin: 50px 0;
  max-width: 350px;
}
.setup-button {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  padding: 10px 20px !important;
  height: 40px !important;
  background-color: rgba(0, 156, 235, 1) !important;
}
.setup-button:hover{
  background-color: rgba(0, 128, 200, 1) !important;
}
.sites-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* override antd */
.ant-collapse-ghost {
  border: 1px solid #cccccc !important;
  margin-bottom: 15px;
}
.ant-collapse-header:hover {
  background: rgba(242, 242, 242, 0.764) !important;
  border-radius: 10px !important;
}
.ant-switch {
  height: 30px !important;
  width: 82px !important;
  border-radius: 10px !important;
  z-index: 5 !important;
}
.ant-switch-checked {
  background-color: #0d2b3a !important;
}
.ant-switch-handle {
  top: 0 !important;
  inset-inline-start: 0 ;
}
.ant-switch-inner-unchecked {
  margin-inline-start: 47px !important;
}
.ant-switch-inner-checked {
  margin-inline-end: 47px !important;
}
.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 40px) !important;
}
.ant-switch .ant-switch-handle {
  inset-inline-start: unset !important;
}
.ant-switch-handle::before {
  height: 30px !important;
  width: 40px !important;
  border-radius: 10px !important;
}
.ant-switch-inner {
  font-family: "Poppins-SemiBold", "Poppins SemiBold", "Poppins", sans-serif !important;
  font-weight: 650 !important;
  font-size: 14px !important;
}
.ant-switch:hover {
  background: rgb(0 0 0 / 25%) !important;
}
.ant-switch-checked:hover {
  background: #0d2b3a !important;
}
.ant-switch-inner-unchecked {
  color: #666666 !important;
}
.ant-collapse-header {
  align-items: center !important;
}

/*         SITE Page Starts      */
.warning-right-header{
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #0d2b3a !important;
  font-size: 16px !important;
}
.warning-right-body{
  font-family: "Poppins-Light", "Poppins Light", "Poppins", sans-serif !important;
  font-weight: 300 !important;
  color: #666666 !important;
  font-size: 14px !important;
  margin-bottom: 30px;
}
.warning-footer-left {
  height: 40px;
  border-radius: 3px !important;
  background-color: #4e6d8b !important;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
.warning-footer-left:hover {
  background-color: #0d2b3a !important;
}
.error-warning-footer-left {
  height: 40px;
  border-radius: 3px !important;
  background-color: #c04040 !important;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
.error-warning-footer-left:hover {
  background-color: #af3333 !important;
  border-color: #c04040 !important;
}
.err-warning-field-p {
  color: #c04040;
  font-family: "Poppins-Light", "Poppins Light", "Poppins", sans-serif !important;
  font-size: 14px !important;
}
.warning-footer-right {
  height: 40px;
  border-radius: 3px !important;
  box-shadow: unset !important;
  border: unset !important;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #666666 !important;
  font-size: 14px !important;
  margin-left: 10px;
}
.warning-footer-right:hover {
  color: #c04040 !important;
  background-color: #fff !important;
}

.modal-button-secondary {
  height: 40px;
  border-radius: 3px !important;
  background-color: #C5C5C5 !important;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
.modal-button-secondary:hover {
  background-color: #979797 !important;
  border-color: #737373 !important;
}

.site-page-container {
  margin: 20px 0%;
}
.site-page-back {
  width: fit-content;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #999999;
  font-size: 14px;
  cursor: pointer;
}
.site-page-back span {
  font-size: 18px;
  margin-right: 10px;
}
.site-page-back:hover {
 color: rgb(0, 156, 235);
}
.site-page-loading-container {
  height: 70vh;
}
.site-page-back, .site-page-header-left, .site-page-header-left-logo ,.site-page-header-right, .site-page-header-right-add {
  display: flex !important;
  align-items: center !important;
}
.site-page-header {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 30px 0;
}

.site-page-header-left-logo {
  justify-content: center;
  height: 70px;
  width: 150px;
  padding: 8px 0;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}
.site-page-header-left-logo img {
  max-height: 100%;
  max-width: 100%;
}
.site-page-header-left-text-name {
  font-size: 20px;
  color: #0d2b3a;
  margin-bottom: 12px;
  font-family: "Poppins-Regular", "Poppins", sans-serif ;
  line-height: 19px;
}
.site-page-header-left-text-org-name {
  font-size: 16px;
  color: #666666;
  font-family: "Poppins-Regular", "Poppins", sans-serif ;
  line-height: 19px;
}
.site-page-header-right-add {
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #e3761c;
  font-size: 16px;
}
.site-page-header-right-add span {
  font-size: 22px;
  color: #e3761c !important;
}
.site-page-flag {
  color:  #c04040;
  font-size: 20px;
}
.site-page-body{
  justify-content: flex-start;
}
.site-page-body span{
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  font-weight: 400;
  text-align: left;
  line-height: normal;
  color: #0d2b3a;
  font-size: 18px;
  
}
.site-page-body p {
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  border: 1px solid #cccccc;
  background-color: #e4e4e4;
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #666666;
  font-size: 12px;
  margin: 0 0 0 20px !important;
}
.studies-table-wrapper {
  overflow-x: auto;
}
.studies-table {
  min-width: 790px !important;
}
.studies-table-header {
  padding: 10px 8px;
  background-color: #f0f0f0;
  margin: 0 !important;
}
.studies-table-header-col {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #666666 !important;
  text-align: left;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.studies-table-header-col:nth-child(2n), .studies-table-body-row .study-col:nth-child(2n) {
  justify-content: center !important;
  text-align: center !important;
}
.studies-table-body {
  max-height: 400px !important;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 0 40px 0;
}
.studies-table-body-row {
  padding: 10px 8px;
  margin: 0 !important;
}
.studies-table-body-row .study-col{
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #666666;
  font-size: 14px;
  line-height: 40px;
  overflow-wrap: break-word;
}
.studies-table-body-row:nth-child(2n) {
  background-color: #fafafa;
}


/*         SITE Page Ends      */




/*       SITES Starts       */

.site-images-carousel-wrapper{
  display: flex;
  align-items: center;
  margin: 0 40px 0 30px;
  overflow: hidden;
}
.site-carousel-prev-button {
  position: absolute;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  left:0;
  cursor: pointer;
  font-size: 22px;
  color: #cccccc;
}
.site-carousel-next-button {
  position: absolute;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  right:0;
  cursor: pointer;
  font-size: 22px;
  color: #cccccc;
}
.site-carousel-next-button:hover, .site-carousel-prev-button:hover {
  color: #666666;
}
.site-images-upload-carousel-Button , .site-carousel-image{
  height: 90px !important;
  width: 140px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e4e4e4;
  margin: 0px 10px;
}
.site-carousel-image img{
  max-height:100% ;
  max-width: 100%;
}
.list-sites-carousel {
  display: flex;
  scroll-behavior:smooth ;
  overflow-x: hidden;
}
.sites-carousel-container {
  position: relative;
  overflow: hidden;
  scroll-behavior:smooth ;
  display: -webkit-inline-box;
}
.sites-header {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 28px;
  margin: 15px 0;
  display: flex;
  align-items: center;
}

.sites-header p {
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  border: 1px solid #cccccc;
  background-color: #e4e4e4;
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #666666;
  font-size: 12px;
  margin: 0 0 0 20px;
}
.sites-left-header {
  background-color: #4e6d8b;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1em;
}
.sites-left-header p {
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  cursor: pointer;
}
.sites-checkbox { 
  padding: 8px 0 8px 10px;
  width: 100%;
  font-family: "Poppins-Light", "Poppins Light", "Poppins", sans-serif !important;
  font-weight: 200;
  color: #666666 !important;
  font-size: 12px;
  margin: 0% !important;
}
.sites-checkbox-checked {
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #666666 !important;
  background-color: #f0f0f0 !important;
  font-weight: 400 !important;
}
.sites-checkbox:hover{
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  background-color: #f0f0f0;
  font-weight: 400 !important;
}
.sites-left-body {
  margin-bottom: 20px;
}

.ant-table {
  border-radius: 0% !important;
  border: 1px solid #e4e4e4;
}


.ant-side-table{
  min-width: 600px;
}
.sites-left {
  overflow-x: auto;
}

.ant-table-title {
  background-color: #4e6d8b !important;
  border-radius: 0% !important;
  border: none !important;
  padding: 0 !important;
}

.title-container {
  padding: 0 16px;
}
.table-header-wrapper, .table-header-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.col-title {
  padding: 5px 16px;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}
.table-header-wrapper-search {
  width: 320px;
}

.ant-table-row:nth-child(2n) {
  background-color: #f2f2f2;
}
.ant-table-row:hover{
  cursor: pointer;
  background-color: #f6f6f6 !important;
  opacity: 1 !important;
}
.table-header-wrapper-title {
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #dae3ec;
  font-weight: 400 !important;
  font-size: 14px;
}

.table_site_name {
  display: flex;
  align-items: center;
}
.table_site_name img{
  max-width: 60px;
  max-height: 30px;
  margin-right: 10px;
  background-color: white;
}
.table_pi_name {
  display: flex;
  align-items: center;
}
.table_pi_name img{
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #009ceb;
}

.principal-list li {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-family: "Poppins-Light", "Poppins Light", "Poppins", sans-serif;
  font-weight: 300 !important;
}

.principal-list li img{
  width: 20px;
  overflow: hidden;
  height: 20px;
  border-radius: 50%;
  border : 1px solid #57c2f7;
  margin-right: 10px;
}
.extra-pi-icon {
  margin-left: 30%;
}
.table-profile-status-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.site-right-arrow{
  font-size: 20px;
  color : #36ade9;
  position: absolute;
  right: 16px;
}
.ant-tabs-tab {
  border-radius: 0% !important;
  background: unset !important;
  border: unset !important;
  transition: none !important;
}
.ant-tabs-tab-active {
  background-color: #fafafa !important;
  border: 1px solid #e4e4e4 !important;
  border-radius: 3px !important;
  border-top: 5px solid #e3761c !important;
}
.ant-tabs-tab-btn{
  font-size: 16px;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  color: #999999 !important;
  font-weight: 500 !important;
  padding: 10px 5px 10px 5px !important;
}
.ant-tabs-tab-btn:hover {
  color: rgb(0, 156, 235) !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#0d2b3a !important;
}

.edit-site-form-label{
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #666666 !important;
  line-height: 40px !important;
  font-size: 14px;
  margin: 10px 10px 10px 0 !important;
}

.edit-site-logo{
    margin: 20px 0;
    border-radius: 5px;
    border: 1px solid #e4e4e4;
    max-width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 250px;
  
}

.edit-site-logo img{
  max-width: 80%;
  max-height: 200px;
}

.facility-card{
  border: 1px solid #cccccc;
  border-radius: 3px !important;
  /* height: 350px; */
  padding: 0 20px 20px 20px;
  width: 87%;
}

.checkbox-facility-site {
  margin: 8px 0;
}
.site-image-upload-card {
  width: 150px;
  height: 90px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 2px 2px 2px 2px;
  border: 1px solid #e4e4e4;
  background-color: #f0f0f0;
}
.site-image-upload-card img{
  max-width: 100%;
  max-height: 100%;
}
.site-image-upload {
  width: 95% !important;
}
/*      Carousel override   */
.site-image-upload .react-multiple-carousel__arrow--right {
  right: 0 !important;
}
.site-image-upload .react-multiple-carousel__arrow--left {
  left: 0 !important;
}
.site-image-upload .react-multiple-carousel__arrow::before {
  color: #666666 !important;
}
.site-image-upload .react-multiple-carousel__arrow {
  background-color: #fff !important;
  box-shadow: #000000 5px !important;
}
/* .site-image-upload .react-multi-carousel-item {
   width: unset !important; 
} */

/*       SITES Ends       */

/*         HOME  END       */


/*       PI Starts         */

.pi-header {
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #0d2b3a !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}
.pi-list-header {
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.add-pi-header {
  margin-right: 15px;
}
.pi-header-check {
  margin-bottom: 30px;
}
.pi-header-name {
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #666666 !important;
  font-size: 16px !important;
  cursor: pointer;
}
.save-pi-button {
  background-color: #4e6d8b !important;
}
.cancel-pi-button {
  background-color: #bfbfbf !important;
}
.save-pi-button, .cancel-pi-button {
  border-radius: 3px !important;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
/* override   */
.crio-input .ant-select-selector {
  height: 100% !important;
  background: transparent !important;
  border: 0 !important;
  border-radius: 0% !important;
}
.ant-select-focused .ant-select-selector {
  outline: unset !important;
  border: none !important;
  box-shadow: none !important;
}
.crio-input .ant-select-selection-item, .crio-input textarea {
  font-size: 14px !important;
  font-family: 'Poppins-Light', 'Poppins Light', 'Poppins', sans-serif !important;
  font-weight: 300 !important;
}
.ant-select-dropdown  {
  font-size: 14px !important;
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #666666 !important;
  font-weight: 300 !important;
  background-color: rgba(250, 251, 252, 1) !important;
}
.ant-select-item-option-selected {
  font-weight: 400 !important;
}

/*       PI Ends         */

/*       STUDY Profile Starts     */

.study-profile-wrapper {
  margin:0 20px;
}
.study-table .ant-table {
  border-radius: 0% !important;
  border: none !important;
}
.study-table .ant-table-tbody >tr >td {
  border-top:  none !important;
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #666666 !important;
  text-align: center !important;
}
.study-table .ant-table-thead >tr>th {
  background-color: #fff !important;
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #666666 !important;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: 450;
}
.study-table .ant-table-thead >tr>th::before{
  all: unset !important;
}
.study-select {
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #666666 !important;
  font-size: 14px !important;
}
.study-profile-wrapper p {
  font-family: "Poppins-ExtraLight", "Poppins ExtraLight", "Poppins", sans-serif !important;
  font-weight: 300 !important;
  color: #666666 !important;
  font-size: 14px !important;
}
.study-profile-body {
  position: relative;
  width: calc(100% - 20px) !important;
}
.left-scroll-icon{
  display: flex;
  justify-content: end;
}
.right-scroll-icon{
  position: absolute;
  z-index: 9;
  top: 35px;
  right: -40px;
}
.left-scroll-icon svg, .right-scroll-icon svg{
  font-size: 28px !important;
  cursor: pointer;
  color: #ccc !important;
}
.left-scroll-icon svg:hover, .right-scroll-icon svg:hover {
  color: #999 !important;
}

.study-tooltip {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #0d2b3a !important;
  line-height: 22px !important;
  padding: 14px 12px !important;
  min-width: 180px !important;
  font-size: 14px;
}
.study-tooltip p {
  color: #666666 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  margin: 5px 0 0 0 !important;
}
.study-tooltip p span {
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  font-weight: 300 !important;
}


/*       STUDY Profile Ends     */

/*       PREVIEW SITE STARTS    */
.site-skaleton-img .ant-skeleton-image {
  height: 100% !important;
}
.preview-site-page-body {
  max-width: 1020px;
  margin: 50px auto;
}
.preview-site-page-body span{
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #0d2b3a !important;
  font-size: 22px !important;
  margin-bottom: 28px !important;
  display: flex !important;
}
.preview-site-page-body p{
    padding: 3px 10px 3px 10px !important;
    border-radius: 3px !important;
    border: 1px solid #cccccc !important;
    background-color: #f0f0f0 !important;
    font-family: "Poppins-Regular", "Poppins", sans-serif !important;
    color: #999999 !important;
    line-height: 25px !important;
    font-size: 12px !important;
    margin: 0 0 0 15px !important;
}
.hasmore-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hasmore-button span {
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #999999 !important;
  text-align: center !important;
  line-height: unset !important;
  font-size: 12px !important;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px !important;
  border: 1px solid #cccccc !important;
  padding: 5px 15px !important ;
  cursor: pointer;
  margin: 20px 0;
  height: 25px;
}
.hasmore-button span p {
  margin: 0 0 0 15px !important;
  padding: 0 7px !important;
  background-color: #fff !important;
}
.hasmore-button span:hover {
  border-color: #009ceb !important;
  color: #009ceb !important;
}
.search-site-about-img {
  width: 100%;
}
.search-site-card-imgs-big, .search-site-card-imgs-small {
  padding: 5px;
  margin: 5px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border : 1px solid #e4e4e4;
}
.search-site-card-imgs-big {
  height: 220px !important;
}
.search-site-card-imgs-small {
  height: 100px !important;
  width: 100%;
}

.search-site-card-imgs-small img, .search-site-card-imgs-big img {
  max-width: 98%;
  max-height: 98%;
}
.site-about-img-left-right-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.site-about-img-left-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 250px;
  margin-top: 20px;
}
.site-about-img-left-right div {
  color: #ababab;
  font-size: 22px;
  z-index: 999;
  cursor: pointer;
}
.site-about-img-left-right div h6{
  color: #606060;
  font-size: 14px;cursor: auto;
}
.site-about-img-left-right div:hover {
  color: #606060;
}
.about-site-table-label {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif !important;
  font-weight: 500 !important;
  color: #666666 !important;
  font-size: 14px !important;
  margin:10px 0;
}
.about-site-table-value {
  margin:11px 0;
}
.about-site-table-value , .about-site-desc{
  font-size: 14px !important;
  font-family: "Poppins-Light","Poppins Light", "Poppins", sans-serif !important;
  color: #666666;
  word-break: break-word;
}
.about-site-table-icon{
  font-size: 22px !important;
}
.about-site-desc {
  margin: 50px 0;
  line-height: normal;
}
.about-site-table span{
  font-size: 16px !important;
}
.site-page-pi-profile-pic {
  height: 128px;
  width: 128px;
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #eee;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

}
.site-page-pi-profile-pic img{
  max-height: 100%;
  max-width: 100%;
}
.about-principal-table-name {
  font-size: 25px !important;
  line-height: normal !important;
}
.about-principal-table-name span {
  font-size: 20px !important;
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #0d2b3a !important;
  margin: 0% !important;
}
.about-principal-table-name-desc {
  font-size: 16px !important;
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #666666 !important;
  margin: 20px 0 40px 0 !important;
}

/*       PREVIEW SITE ENDS    */

/*       DASHBOARD STARTS     */
.dashboard-body {
  margin-top: 40px;
}
.dashboard-sites-left {
  padding: 15px 0;
}
.dashboard-divider {
  height: -webkit-fill-available;
  border-inline-start: 1px dashed #cccccc !important;
  margin-bottom: 30px;
}
.dashboard-sites-header {
  margin: 0 25px 10px 0;
}
.dashboard-search-icon {
  color: #cccccc;
  font-size: 20px;
  cursor: pointer;
}
.dashboard-search {
  width: 100%;
  margin-bottom: 20px;
}
.sites-checkbox-span {
  position: absolute;
  right: 20px;
  background: #d4d4d4;
  padding: 3px 10px;
  margin-top: -4px;
  border-radius: 3px;
}
.dashboard-area-container {
  display: flex !important;
  flex-direction: column !important;
  background: #fff;
  max-height: 300px !important;
  overflow-x: auto;
}
.dashboard-checkbox-wrapper {
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #666 !important;
  font-size: 11px !important;
  background: #fafafa !important;
  padding: 15px 10px 25px 10px !important;
  margin-bottom: 0px !important;
}
.dashboard-checkbox-wrapper p{
  margin: 0 0 5px 0 !important;
}
.dashboard-view-public-container {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
}
.dashboard-view-public-container img{
  max-width: 80%;
}
.dashboard-cta {
  width: 100%;
  display: flex;
  justify-content: center;
}
.view-as-public {
  width: 169px;
  height: 40px;
  padding: 10px 20px 10px 20px;
  border-radius: 3px;
  background-color: #cccccc;
  box-sizing: border-box;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #ffffff !important;
  text-align: center;
}
.view-as-public:hover {
  /* outline: unset !important; */
  border-color: rgb(181,181,181) !important;
  background-color: rgb(181,181,181) !important;
}
.dashboard-sites-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-sites-right-header {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 16px;
}
.dashboard-sites-right-header span , .dashboard-site-card-pi-header span, .img-count-span{
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #999999 !important;
  border: 1px solid #cccccc !important;
  background-color: #f0f0f0 !important;
  padding: 3px 8px;
  border-radius: 3px !important;
  margin-left: 10px;
  font-size: 12px !important;
}
.dashboard-sites-sort, .dashboard-sites-sort-buttons-wrapper{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;
}
.dashboard-sites-sort p{
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #999999;
  font-size: 14px !important;
}
.dashboard-sites-sort-buttons-active,.dashboard-sites-sort-buttons {
  padding: 10px 20px 10px 20px !important;
  /* border-radius: 3px !important; */
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #ffffff !important;
  font-size: 12px !important;
}
.dashboard-sites-sort-buttons-active {
  background-color: #e3761c !important;
}
.dashboard-sites-sort-buttons {
  color: #666666 !important;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}
.dashboard-site-cards-wrapper {
  margin: 27px 0;
  width: 100%;
}

.dashboard-site-is-active-true, .dashboard-site-is-active-false {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.dashboard-site-is-active-true {
  background-color: #13b639;
}
.dashboard-site-is-active-false {
  background-color: #999999;
}
.dashboard-site-card-img {
  width: 100%;
  margin-top: 24px
}
.dashboard-site-card-img img {
  width: 100%;
}
.dashboard-site-card-header {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
}
.dashboard-site-card-header-name {
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  font-weight: 500;
  color: #0d2b3a;
  font-size: 18px;
}
.dashboard-site-card-header-name span {
  font-size: 16px !important;
  margin-left: 15px;
}
.dashboard-site-card-header-cta {
  color: #009ceb;
  font-size: 22px;
  cursor: pointer;
  right: 35px;
  position: absolute;
  z-index: 9999;
}
.dashboard-site-card-header-cta:hover {
  color: #008cd2;
}
.dashboard-site-details-tags {
  display: flex;
  align-items: center;
}
.dashboard-site-details-tags p{
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #666666;
  font-size: 16px ;
  margin: 0 10px 0 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-site-details-tags span {
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #999999;
  font-size: 12px;
  padding-right: 10px;
  max-width: 100px;
}
.dashboard-site-description {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Poppins-ExtraLight", "Poppins ExtraLight", "Poppins", sans-serif !important;
  font-weight: 300 !important;
  color: #666666 !important;
  margin: 15px 30px 0 0;
}
.therapeutic-list-title {
  display: flex;
}
.dashboard-site-card-pi-header {
  font-family: "Poppins-Regular", "Poppins", sans-serif !important;
  color: #0d2b3a !important;
  font-size: 12px !important;
}
.dashboard-site-card-pi-body-img{
  height: 23px;
  width: 23px;
  overflow: hidden;
  border: 1px solid #cccccc;
  border-radius: 50%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.dashboard-site-card-pi-body-img img{
  max-height: 100%;
  max-width: 100%;
}
.dashboard-site-card-pi-body-name{
  font-family: "Poppins-Regular", "Poppins", sans-serif;
  color: #666666;
  font-size: 14px !important;
}
.dashboard-site-card-pi-body{
  margin-bottom: 10px;
}
.dashboard-see-more  {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 22px;
  height: 30px;
  color:#666666;
}
.dashboard-see-more-icon{
  cursor: pointer;
}
.dashboard-see-more-icon:hover {
  color: #009ceb;
  margin-bottom: 2px;
}
.dashboard-site-card {
  /* width: 100%; */
  border : 1px solid #e4e4e4;
  margin-bottom: 20px;
  padding: 24px;
}

.dashboard-site-card:hover {
  box-shadow: 0px 0px 20px 0px rgb(192 192 192 / 80% );
}
.dashboard-body .ant-fade-leave {
  display: none !important;
}
.dashboard-body .ant-float-btn-icon {
  color: #fff !important;
}
.dashboard-body .ant-float-btn-body {
background-color: #66bbad !important;
}

/*       DASHBOARD ENDS       */


/* Responsive media */

@media (max-width: 991px) {
  .logo img {
    height: 40px;
  }
  .header {
    height: 60px;
    padding: 0 7%;
  }
  .header ul li Button {
    font-size: 15px !important;
  }
  .op-warning-card {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .footer-rights {
    flex-direction: column;
    height: 120px;
    line-height: normal !important;
    margin-bottom: 40px;
  }
  .crio-input .op-form-button-wrapper{
    width: 100%;
  }
  .logo img {
    height: 35px;
  }
  .header {
    height: 50px;
    padding: 5px 6%;
  }
  .header ul li Button {
    font-size: 12px !important;
  }
  .org-nav-name {
    font-size: 14px;
    margin: 0 15px;
  }
  .profile-nav-img {
    height: 35px;
    width: 35px;
  }
  .agreement-card {
    margin: 50px 0;
    padding: 10px 10px;
  }
  .agreement-body {
    padding: 20px;
  }
  .hero-section {
    margin-top: 10%;
    left : 0%;
  }
  .op-warning-card-wrapper {
    display: none;
  }
  .op-label{
    margin: 0;
  }
  .op-checkbox{
    font-weight: 300 !important;
  }
}
@media (max-width: 500px) {
  .logo img , .footer-branding img{
    height: 30px;
  }
  .header {
    height: 50px;
    padding: 10px 6%;
  }
  .header ul li Button {
    font-size: 10px !important;
  }
  .org-nav-name {
    font-size: 12px;
    margin: 0 12px;
  }
  .profile-nav-img {
    height: 30px;
    width: 30px;
  }
}
  